import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "../i18n/en/default.json"
import enEvents from "../i18n/en/events.json"
import enVariables from "../i18n/en/variables.json"
import es from "../i18n/es/default.json"
import esEvents from "../i18n/es/events.json"
import esVariables from "../i18n/es/variables.json"
import pt from "../i18n/pt/default.json"
import ptEvents from "../i18n/pt/events.json"
import ptVariables from "../i18n/pt/variables.json"
import fr from "../i18n/fr/default.json"
import frEvents from "../i18n/fr/events.json"
import frVariables from "../i18n/fr/variables.json"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'language',
        },
        ns: ['default', 'events', 'variables'], // Define tus namespaces
        defaultNS: 'default',            // Define el namespace por defecto
        resources: {
            en: {
                default: en,
                events: enEvents,
                variables: enVariables
            },
            es: {
                default: es,
                events: esEvents,
                variables: esVariables
            },
            fr: {
                default: fr,
                events: frEvents,
                variables: frVariables
            },
            pt: {
                default: pt,
                events: ptEvents,
                variables: ptVariables
            },
        }
    });

export default i18n;