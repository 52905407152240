'use client'

import { Icon } from '@/app/components/Icon'
import { notification } from 'antd'
import React, { createContext, ReactNode, useCallback } from 'react'
import ToastStyles from '@/app/components/Toast/Toast.module.scss'

export const FEEDBACK_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
} as const

export type FeedbackType = (typeof FEEDBACK_TYPE)[keyof typeof FEEDBACK_TYPE]

export const FeedbackContext = createContext<{
  openFeedback: (type: FeedbackType, message: string) => void
  feedbackComponent: ReactNode | null
}>({
  openFeedback: () => {},
  feedbackComponent: null,
})

export const FeedbackContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [api, contextHolder] = notification.useNotification()

  const openFeedback = useCallback(
    (type: 'error' | 'success', message: string) => {
      api[type]({
        message,
        closeIcon: false,
        icon:
          type === 'success' ? (
            <Icon
              icon='cds-ico-check-circle-solid'
              cdsColorToken='--cds-color-text-inverted'
            />
          ) : (
            <Icon
              icon='cds-ico-close-circle-solid'
              cdsColorToken='--cds-color-text-inverted'
            />
          ),
        placement: 'topRight',
        className: `${ToastStyles.cdsToast} ${
          type === 'success'
            ? ToastStyles.cdsToastSuccess
            : ToastStyles.cdsToastError
        }`,
      })
    },
    [api],
  )

  return (
    <FeedbackContext.Provider
      value={{ openFeedback, feedbackComponent: contextHolder }}
    >
      {children}
    </FeedbackContext.Provider>
  )
}
